import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container, DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { Pre } from "../../components/pre"
import { userDelete, userGet, userPost } from "../../store/users/userActions"
import { Link } from "react-router-dom"
import moment from "moment"
import { DataTableList } from "../../components/tables/dataTable"
import CompanyForm from "../company/forms/companyForm"
import { companyPost } from "../../store/companies/companyActions"
import UserForm from "./forms/userForm"

export const Users = props => {
  const dispatch = useDispatch()
  const { Users } = useSelector(state => state)

  const { loading, users } = Users

  const [user, setUser] = useState(false)
  const [form, setForm] = useState(false)


  const get = () => dispatch(userGet())

  const onEdit = (user) => {
    setUser(user.id)
    setForm(true)
  }

  const toggleForm = () => {
    setUser(false)
    setForm(false)
  }

  const onCreate = () => {
    setUser(0)
    setForm(true)
  }


  document.title = `${typeof props.employees !== 'undefined'? 'Employees' : 'Users' } | Q-SYS`
  useEffect(() => get(), [])

  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          title={loading ? "Loading" : `${typeof props.employees !== 'undefined'? 'Employees' : 'Users' }`}
          breadcrumbItem={typeof props.employees !== 'undefined'? 'Employees' : 'Users' }
        />

        <Card className="bg-transparent">
          <CardBody>

            <div className="btn-group float-end">
              <Button onClick={onCreate} className="btn btn-primary btn-soft-primary">
                <i className="mdi mdi-plus font-size-18"></i>
                Create
              </Button>
            </div>

            <CardTitle>{typeof props.employees !== 'undefined'? 'Employees' : 'Users' } </CardTitle>

          </CardBody>


          {/*<Pre data={users} />*/}

          <UsersList
            get={get}
            data={users.data}
            loading={loading}

          />

        </Card>

      </Container>
    </div>
    <UserForm toggle={toggleForm}
                 isOpen={form}
                 user={user}
                 saveChanges={data => {
                   setForm(false)
                   dispatch(userPost(data, get))
                   // setCompany(false)
                 }

                 } />
  </React.Fragment>
}


export const UsersList = props => {

  // return <Pre data={props.data}/>
  const dispatch = useDispatch()

  const onDelete = item => dispatch(userDelete(item.id))

  const columns = [
    {
      name: "#",
      selector: "id",
      style: { width: "60px" },
      cell: (row) => <img src={row.avatar} height={60} className="rounded-circle" />
    },
    {
      name: "Name",
      selector: "name",
      cell: (row) => <>
        <div>
          <Link to={`/user/details/${row.id}`}><strong>{row.name}</strong></Link></div>
        <div>
          <><small>{row.email}</small></>
        </div>
      </>
    },
    {
      name: "Phone",
      selector: "phone",
      cell: (row) => <>
        <div>
          <Link to={`tel:${row.mobile_number}`}><strong>{row.mobile_number}</strong></Link></div>

      </>
    },
    {
      name: "Roles",
      selector: "roles",
      cell: ({id, roles }) => <>
        <div>
          {roles.map(role => <span className="badge badge-soft-primary m-1" key={`user-${id}-${role.id}`} to={`tel:${role.name}`}>
            <strong>{role.name}</strong>
          </span>)}
        </div>

      </>
    },

    {
      name: "",
      selector: "id",
      className: "flex-end",
      cell: (row) => <UncontrolledDropdown>
        <DropdownToggle
          href="#"
          className="card-drop"
          tag="a"
        >
          <i className="mdi mdi-dots-horizontal font-size-18" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            href="#"
            onClick={() => onEdit(row)}
          >
            <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
            Edit
          </DropdownItem>
          <DropdownItem
            href="#"
            onClick={() => onDelete(row)}
          >
            <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
            Delete
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ]
  return <>
    {props.data && props.data.length > 0 && <>
      <DataTableList

        columns={columns}
        get={props.get}
        className={`project-list-table table-nowrap align-middle table-borderless`}
        current_page={1}
        last_page={2}
        data={props.data}
        callBack={props.get}
        loading={props.loading}
        setCurrentPage={() => {
        }}

      />
    </>

    }</>

}


