import {
  ROLE_GET_REQUEST,
  ROLE_GET_SUCCESS,
  ROLE_GET_FAILURE,
  ROLE_FIND_REQUEST,
  ROLE_FIND_SUCCESS,
  ROLE_FIND_FAILURE,
  ROLE_POST_REQUEST,
  ROLE_POST_SUCCESS,
  ROLE_POST_FAILURE,
  ROLE_ACTION_REQUEST,
  ROLE_ACTION_SUCCESS,
  ROLE_ACTION_FAILURE

} from "./roleTypes"
import axios from "axios"


export const roleGet = () => dispatch => {
  dispatch({ type: ROLE_GET_REQUEST })
  axios.get("/app/roles/get")
    .then(({ data }) => dispatch({ type: ROLE_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ROLE_GET_FAILURE, payload: message }))
}

export const roleFind = (id) => dispatch => {
  dispatch({ type: ROLE_FIND_REQUEST })
  axios.get(`/app/roles/get/${id}`)
    .then(({ data }) => dispatch({ type: ROLE_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ROLE_FIND_FAILURE, payload: message }))
}

export const roleDelete = (id, callBack) => dispatch => {
  dispatch({ type: ROLE_ACTION_REQUEST })
  axios.post(`/app/roles/delete/`, {id})
    .then(({ data }) => {
      dispatch({ type: ROLE_ACTION_SUCCESS, payload: data })
      dispatch(roleGet())

    })
    .catch(({ message }) => dispatch({ type: ROLE_ACTION_FAILURE, payload: message }))
    .finally(callBack)
}

export const rolePost = (data, callBack) => dispatch => {
  dispatch({ type: ROLE_POST_REQUEST })
  axios.post(`/app/roles/create`, data)
    .then(({ data }) => {
      dispatch({ type: ROLE_POST_SUCCESS, payload: data })
      if (!callBack) {
        dispatch(roleGet())
        dispatch(roleFind(data.id))
      } else {
        callBack(data.id)
      }
    })
    .catch(({ message }) => dispatch({ type: ROLE_POST_FAILURE, payload: message }))
}



