import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container, DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { questionDelete, questionGet, questionPost } from "../../store/questions/questionActions"
import { Link } from "react-router-dom"
import moment from "moment"
import { DataTableList } from "../../components/tables/dataTable"
import { Pre } from "../../components/pre"
import QuestionForm from "./forms/questionForm"

export const Questions = props => {
  const dispatch = useDispatch()
  const { Question } = useSelector(state => state)

  const { loading, questions } = Question

  const [question, setQuestion] = useState(false)
  const [form, setForm] = useState(false)


  const get = () => dispatch(questionGet())

  const onEdit = (question) => {
    setQuestion(question.id)
    setForm(true)
  }

  const toggleForm = () => {
    setQuestion(false)
    setForm(false)
  }

  const onCreate = () => {
    setQuestion(0)
    setForm(true)
  }


  document.title = "Questions | Q-SYS"
  useEffect(() => get(), [])

  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          title={loading ? "Loading" : "Questions"}
          breadcrumbItem="Questions"
        />

        <Card className="bg-transparent">
          <CardBody>

            <div className="btn-group float-end">
              <Button onClick={onCreate} className="btn btn-primary btn-soft-primary">
                <i className="mdi mdi-plus font-size-18"></i>
                Create
              </Button>
            </div>
            <CardTitle>Questions </CardTitle>

          </CardBody>



          <QuestionsList
            get={get}
            data={Question.questions.data}
            loading={loading}
          />

        </Card>

      </Container>
    </div>
    <QuestionForm toggle={toggleForm}
                  isOpen={form}
                  question={question}
                  saveChanges={data => {
                   setForm(false)
                   dispatch(questionPost(data, get))
                   // setCompany(false)
                 }

                 } />

  </React.Fragment>
}


export const QuestionsList = props => {

   // return <Pre data={props.data}/>
  const dispatch = useDispatch()

  const onDelete = item => dispatch(questionDelete(item.id))

  const columns = [
    {
      name: "#",
      selector: "id",
    },
    {
      name: "Name",
      selector: "name",
      cell: (row) => <>
         <div>
           <Link to={`/questions/${row.id}`}><strong>{row.question}</strong></Link></div>

       </>
     },

     {
       name: "Department",
       selector: "department",
       cell: (row) => row.department_name // todo change to license_expiry
     },
     {
       name: "Aspect",
       selector: "aspect",
       cell: (row) => row.aspect_name // todo change to license_expiry
     },
     {
       name: "Type",
       selector: "type",
       cell: (row) => row.question_type // todo change to license_expiry
     },

     {
       name: "",
       selector: "id",
       className:"flex-end",
       cell: (row) =>   <UncontrolledDropdown>
         <DropdownToggle
           href="#"
           className="card-drop"
           tag="a"
         >
           <i className="mdi mdi-dots-horizontal font-size-18" />
         </DropdownToggle>
         <DropdownMenu className="dropdown-menu-end">
           <DropdownItem
             href="#"
              onClick={() => onEdit(row)}
           >
             <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
             Edit
           </DropdownItem>
           <DropdownItem
             href="#"
              onClick={() => onDelete(row)}
           >
             <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
             Delete
           </DropdownItem>
         </DropdownMenu>
       </UncontrolledDropdown>
     }
  ]
  return <>
    {props.data && props.data.length > 0 && <>
      <DataTableList
        columns={columns}
        get={props.get}
        className={`project-list-table table-nowrap align-middle table-borderless`}
        current_page={1}
        last_page={2}
        data={props.data}
        callBack={props.get}
        loading={props.loading}
        setCurrentPage={() => {
        }}
      />
    </>

    }</>

}


