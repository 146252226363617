import React from "react"
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { Pre } from "../../../components/pre"
import axios from "axios"


export default class UserForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      fields: false,
      form: false
    }
  }

  componentDidMount = () => this.init()

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen == true) this.init()
  }

  init = () => {
    const { department } = this.props
    axios
      .post("app/users/form", { id: this.props.user })
      .then(res => this.setState({ form: res.data, fields: res.data.fields }))
  }

  handleChange = (label, value) => {
    let { fields } = this.state
    fields[label] = value
    this.setState({ fields })
  }

  render() {
    const { isOpen, toggle, user, saveChanges } = this.props
    const { loading, fields, form } = this.state
    return <Modal isOpen={isOpen} toggle={toggle}>
      {!loading && <>
        <ModalHeader>

          {user.id && <>Edit : {user.name}</>}
          {!user.id && <>Create User</>}
        </ModalHeader>
        <ModalBody>

          <div className="mb-3">
            <Label htmlFor="form-department-name">Company</Label>
            <select
              value={fields.companies_id}
              onChange={e => this.handleChange("companies_id", e.target.value)}
              type="text"
              className="form-control"
              id="form-department-name"
              placeholder=""
            >
              <option>SELECT COMPANY</option>

              {form.companies && typeof form.companies === "object" && form.companies.map(row => <option
                key={`company-${row.id}`} value={row.id}>{row.company_name}</option>)}

            </select>
          </div>

          <div className="mb-3">
            <Label htmlFor="form-department-name">Full Name</Label>
            <Input
              value={fields.name}
              onChange={e => this.handleChange("name", e.target.value)}
              type="text"
              className="form-control"
              id="form-department-name"
              placeholder=""
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="form-department-name">Email</Label>
            <Input
              value={fields.email}
              onChange={e => this.handleChange("email", e.target.value)}
              type="text"
              className="form-control"
              id="form-department-name"
              placeholder=""
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="form-department-name">Mobile Number</Label>
            <Input
              value={fields.mobile_number}
              onChange={e => this.handleChange("mobile_number", e.target.value)}
              type="text"
              className="form-control"
              id="form-department-name"
              placeholder=""
            />
          </div>


          <div className="mb-3">
            <Label htmlFor="form-department-name">Gender</Label>
            <select
              value={fields.gender_label}
              onChange={e => this.handleChange("gender_label", e.target.value)}
              type="text"
              className="form-control"
              id="form-gender_label-name"
              placeholder=""
            >
              <option>SELECT </option>

              {form.gender && typeof form.gender === "object" && form.gender.map(row => <option
                key={`gender-${row.id}`} value={row.id}>{row.gender_label}</option>)}

            </select>
          </div>
          {/*{form.roles.map(role => <div key={`user-form-role-${role.id}`}*/}
          {/*                             className="form-check mb-3">*/}
          {/*  <input*/}
          {/*    className="form-check-input"*/}
          {/*    type="checkbox"*/}
          {/*    value=""*/}
          {/*    id={`user-form-role-${role.id}`}*/}
          {/*  />*/}
          {/*  <label*/}
          {/*    className="form-check-label"*/}
          {/*    htmlFor={`user-form-role-${role.id}`}*/}
          {/*  >*/}
          {/*    {role.name}*/}
          {/*  </label>*/}
          {/*</div>)}*/}

          {/*<Pre data={form.roles} />*/}

        </ModalBody>
        <ModalFooter>
          <Button outline color="dark" onClick={toggle}>Close</Button>
          <Button outline color="success" onClick={() => saveChanges(fields)}>
            Save Changes</Button>
        </ModalFooter>
      </>}
    </Modal>
  }


}
