import React, { useEffect, useState } from "react"
import { Col, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap"


export const DataTableList = (
  {
    setCurrentPage,
    get,
    data,
    columns,
    className,
    current_page = 0,
    from = 0,
    last_page = 0,
    per_page = 0,
    to = 0,
    total = 0
  }) => {

  const handleNext = () => setCurrentPage ? setCurrentPage(current_page + 1) : get(current_page + 1)
  const handlePrev = () => setCurrentPage ? setCurrentPage(current_page - 1) : get(current_page - 1)
  const handleLast = () => setCurrentPage ? setCurrentPage(last_page) : get(last_page)
  const handleFirst = () => setCurrentPage ? setCurrentPage(1) : get(1)

  const renderPages = () => {
    let pages = []
    for (let index = 1; index <= last_page; index++) {
      pages.push(<PaginationItem active={index === current_page}>
        <PaginationLink onClick={() => setCurrentPage ? setCurrentPage(index) : get(index)}>{index}</PaginationLink>
      </PaginationItem>)
    }
    return pages
  }

  return <div>
    <Table className={className}>
      {true && <thead>
      <tr>
        {columns.map((col, index) => <th hidden={col.omit}
                                         style={{ maxWidth: col.width, minWidth: col.width }}
                                         key={index}>
          {<span>{col.name}</span>}

        </th>)}
      </tr>
      </thead>}
      <tbody>
      {data.map((record, index) => {
        if (!record || record === null) return false
        return <tr key={index}>
          {columns.map((col, i) => <td style={col.style}
                                       className={col.className}
                                       key={`${index}-${i}`}
                                       hidden={col.omit}>
            {true && typeof col.cell === "function" && <div>{col.cell(record, index)}</div>}
            {true && typeof col.cell === "undefined" && <>{record[col.selector]}</>}
          </td>)}
        </tr>
      })}
      </tbody>
    </Table>
    {!(current_page === 1 && last_page === 1) && <div className="justify-content-center align-self-center text-center">
      <Row>
        <Col sm={10} className="justify-content-center align-self-center text-center">

          {data.length > 0 && <Pagination
            aria-label="Page navigation example"
          >
            <PaginationItem disabled={current_page === 1}>
              <PaginationLink onClick={handleFirst}>
                <i className="mdi mdi-chevron-double-left"></i>
              </PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={current_page === 1}>
              <PaginationLink onClick={handlePrev}>
                <i className="mdi mdi-chevron-left"></i>
              </PaginationLink>
            </PaginationItem>
            {renderPages()}
            <PaginationItem disabled={current_page >= last_page}>
              <PaginationLink onClick={handleNext}><i className="mdi mdi-chevron-right"></i></PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={current_page >= last_page}>
              <PaginationLink onClick={handleLast}><i className="mdi mdi-chevron-double-right"></i></PaginationLink>
            </PaginationItem>
          </Pagination>}
        </Col>
        <Col sm={2}>
          {true && <div className=" align-content-end justify-content-end pt-2 right">
            {`Page : ${current_page} / ${last_page}`}
          </div>}
        </Col>
      </Row>
    </div>}

  </div>
}
