import React from "react"
import { Badge, Progress } from "reactstrap"

export const statusColor = status => {
 switch (status?.toLowerCase()) {
   case "initiated" : {
     return "dark"
   }
   case "activated" : {
     return "success"
   }
   case "enabled" : {
     return "success"
   }
   case "disabled" : {
     return "danger"
   }
   case "suspended" : {
     return "dark"
   }
   case "deactivated" : {
     return "warning"
   }
   default : {
     return "danger"
   }

 }
}


export const NegativeLevel = (props, { value, max }) => {
  return <Progress color={`info`} value={value} max={max}  {...props}
                   title={value} />
}

export const StatusBadge = (props) => {
  const { status } = props



  return <Badge color={ statusColor(status)}>{status ? status.toUpperCase() : "UNDEFINED"}</Badge>

}

