import {
  ROLE_GET_REQUEST,
  ROLE_GET_SUCCESS,
  ROLE_GET_FAILURE,
  ROLE_FIND_REQUEST,
  ROLE_FIND_SUCCESS,
  ROLE_FIND_FAILURE,
  ROLE_POST_REQUEST,
  ROLE_POST_SUCCESS,
  ROLE_POST_FAILURE,
  ROLE_ACTION_REQUEST, ROLE_ACTION_SUCCESS, ROLE_ACTION_FAILURE
} from "./roleTypes"



const init = {
  loading: false,
  roles : [],
  role: false,
  error : ""
}

const Roles = (state = init, action) => {
  switch(action.type) {
    case ROLE_GET_REQUEST : {return {...state, loading: true, error: ""}}
    case ROLE_GET_SUCCESS : {return {...state, loading: false, roles: action.payload, error: ""}}
    case ROLE_GET_FAILURE : {return {...state, loading: false, error: action.payload}}

    case ROLE_FIND_REQUEST : {return {...state, loading: true, error: "", role: false}}
    case ROLE_FIND_SUCCESS : {return {...state, loading: false, role: action.payload, error: ""}}
    case ROLE_FIND_FAILURE : {return {...state, loading: false, error: action.payload}}

    case ROLE_POST_REQUEST : {return {...state, loading: true, error: ""}}
    case ROLE_POST_SUCCESS : {return {...state, loading: false, error: ""}}
    case ROLE_POST_FAILURE : {return {...state, loading: false, error: action.payload}}

    case ROLE_ACTION_REQUEST : {return {...state, loading: true, error: ""}}
    case ROLE_ACTION_SUCCESS : {return {...state, loading: false, error: ""}}
    case ROLE_ACTION_FAILURE : {return {...state, loading: false, error: action.payload}}


    default : {
      return { ...state }
    }
  }

}

export default Roles;
