import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams, useParams } from "react-router-dom"
import { Blank } from "../index"
import { companyFind } from "../../store/companies/companyActions"


export const CompanyDetails = props => {
  const dispatch = useDispatch()
  const { Company } = useSelector(state => state)
  const { company,loading } = Company
  const { id } = useParams()

  const get = () => {
    console.log(id)
    dispatch(companyFind(id))
  }

  useEffect(get, [])

  return <Blank company={company}
                title={loading ? "Loading" : "Company Details"} />

}
