import React from "react"
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { Pre } from "../../../components/pre"
import axios from "axios"


export default class EscalationLevelForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      fields: false,
      form: false
    }
  }

  componentDidMount = () => this.init()

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen == true) this.init()
  }

  init = () => {
    if (!this.props.isOpen) return false

    const { escalation_level } = this.props
    axios
      .post("app/escalation/level/form/", { id: escalation_level })
      .then(res => this.setState({ form: res.data, fields: res.data.fields }))
  }

  handleChange = (label, value) => {
    let { fields } = this.state
    fields[label] = value
    this.setState({ fields })
  }

  render() {
    const { isOpen, toggle, escalation_level, saveChanges } = this.props
    const { loading, fields, form } = this.state
    return <Modal isOpen={isOpen} toggle={toggle}>
      <Form method="post" onSubmit={e => {
        e.preventDefault()
        saveChanges(fields)
      }}>
        <ModalHeader>
          {loading && "Loading...."}

          {escalation_level.id && <>Edit : {escalation_level.escalation_level}</>}
          {!escalation_level.id && <>Create Escalation Level</>}
        </ModalHeader>
        <ModalBody>

          <div className="mb-3">
            <Label htmlFor="form-escalation_level-name">Escalation Level</Label>
            <Input
              value={fields.escalation_level}
              onChange={e => this.handleChange("escalation_level", e.target.value)}
              type="text"
              className="form-control"
              id="form-escalation_level-name"
              placeholder=""
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button outline color="dark" onClick={toggle}>Close</Button>
          <Button outline color="success" type="submit">
            Save Changes</Button>
        </ModalFooter>
      </Form>
    </Modal>
  }


}
