import {
  QUESTION_GET_REQUEST,
  QUESTION_GET_SUCCESS,
  QUESTION_GET_FAILURE,
  QUESTION_FIND_REQUEST,
  QUESTION_FIND_SUCCESS,
  QUESTION_FIND_FAILURE,
  QUESTION_POST_REQUEST,
  QUESTION_POST_SUCCESS,
  QUESTION_POST_FAILURE,
  QUESTION_ACTION_REQUEST,
  QUESTION_ACTION_SUCCESS,
  QUESTION_ACTION_FAILURE

} from "./questionTypes"
import axios from "axios"


export const questionGet = () => dispatch => {
  dispatch({ type: QUESTION_GET_REQUEST })
  axios.get("/app/questions/get")
    .then(({ data }) => dispatch({ type: QUESTION_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: QUESTION_GET_FAILURE, payload: message }))
}

export const questionFind = (id) => dispatch => {
  dispatch({ type: QUESTION_FIND_REQUEST })
  axios.get(`/app/questions/get/${id}`)
    .then(({ data }) => dispatch({ type: QUESTION_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: QUESTION_FIND_FAILURE, payload: message }))
}

export const questionDelete = (id, callBack) => dispatch => {
  dispatch({ type: QUESTION_ACTION_REQUEST })
  axios.post(`/app/questions/delete/`, {id})
    .then(({ data }) => {
      dispatch({ type: QUESTION_ACTION_SUCCESS, payload: data })
      dispatch(questionGet())

    })
    .catch(({ message }) => dispatch({ type: QUESTION_ACTION_FAILURE, payload: message }))
    .finally(callBack)
}

export const questionPost = (data, callBack) => dispatch => {
  dispatch({ type: QUESTION_POST_REQUEST })
  axios.post(`/app/questions/submit`, data)
    .then(({ data }) => {
      dispatch({ type: QUESTION_POST_SUCCESS, payload: data })
      if (!callBack) {
        dispatch(questionGet())
        dispatch(questionFind(data.id))
      } else {
        callBack(data)
      }
    })
    .catch(({ message }) => dispatch({ type: QUESTION_POST_FAILURE, payload: message }))
}
