import React, { useEffect, useState } from "react"
import {
  Button,
  Card, CardBody, CardTitle,
  Container, DropdownItem, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink,
  Table, UncontrolledDropdown
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  branchActivate,
  branchDeactivate,
  branchDelete,
  branchDuplicate,
  branchGet,
  branchPatch,
  branchPost, branchSuspend
} from "../../store/branches/branchActions"
import { Link } from "react-router-dom"
import { NegativeLevel, StatusBadge } from "../../components/general"
import moment from "moment"
import BranchForm from "./forms/branchForm"
import { DataTableList } from "../../components/tables/dataTable"
import { companyActivate, companyDeactivate, companySuspend } from "../../store/companies/companyActions"


export const Branches = (props) => {

  const [form, setForm] = useState(false)
  const [branch, setBranch] = useState(false)

  const dispatch = useDispatch()
  const { Branch } = useSelector(state => state)
  const { loading, error, branches } = Branch

  const get = () => dispatch(branchGet())

  useEffect(() => {
    get()
  }, [])

  const onEdit = (branch) => {
    setBranch(branch)
    setForm(true)
  }

  const toggleForm = () => {
    setBranch(false)
    setForm(false)
  }

  const onCreate = () => {
    setBranch({
      id: null,
      license_status_id: null,
      companies_id: null,
      branch_name: null,
      branch_email: null,
      branch_phone: null,
      branch_logo: null,
      branch_location: null,
      negative_review_level: null,
      license_key: null,
      license_expiry: null,
      created_by: null,
      created_at: moment().format("YYYY-MM-DD hh:mm:ss"),
      updated_at: moment().format("YYYY-MM-DD hh:mm:ss")
    })
    setForm(true)
  }

  const onDelete = (branch) => dispatch(branchDelete(branch.id))

  const onDuplicate = row => dispatch(branchDuplicate(row, get))

  const columns = [
    {
      name: "#",
      selector: "id",
      style: { width: 40, alignText: "center", fontWeight: "bold" },
      cell: (row, index) => index + 1 //<img src={require("../../assets/images/companies/img-1.png")} height={25} />
    },
    {
      name: "Logo",
      selector: "id",
      style: { width: 60, alignText: "center" },
      cell: (row) => <img src={require("../../assets/images/companies/img-1.png")} height={25} />
    },
    {
      name: "Name",
      selector: "name",
      cell: (row) => <>
        <div>
          <Link to={`/companies/branch/${row.id}`}><strong>{row.branch_name}</strong></Link></div>
        <div>
          <><StatusBadge status={row.status} {...props} />{" "}<small>{row.branches_id}</small></>
        </div>
      </>
    },
    {
      name: "contacts",
      selector: "branch_email",
      cell: (row) => <>
        <div><i className="bx bx-phone"></i> {row.branch_phone}</div>
        <div><i className="bx bx-at"></i> {row.branch_email}</div>
      </>
    },
    {
      name: "location",
      selector: "location",
      cell: (row) => row.location
    },
    {
      name: "Country",
      selector: "country_name",
      cell: ({ country_name }) => country_name
    },
    {
      name: "license_expiry",
      selector: "license_expiry",
      cell: (row) => moment(row.license_expiry).format("Do MMM YYYY")
    },
    {
      name: "",
      selector: "id",
      cell: (row) => <UncontrolledDropdown>
        <DropdownToggle
          href="#"
          className="card-drop"
          tag="a"
        >
          <i className="mdi mdi-dots-horizontal font-size-18" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            href="#"
            onClick={() => onEdit(row)}
          >
            <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
            Edit
          </DropdownItem>


          {(true || ['deactivated', 'suspended', 'initiated'].includes(row.status)) && <DropdownItem
            href="#"
            onClick={() => onDelete(row)}
          >
            <i className="mdi mdi-trash-can-outline font-size-16 text-danger me-1" />{" "}
            Delete
          </DropdownItem>}
          {(true || ['activated'].includes(row.status)) && <DropdownItem
            href="#"
            onClick={() => dispatch(branchDeactivate(row.id))}
          >
            <i className="mdi mdi-stop-circle-outline font-size-16 text-warning me-1" />{" "}
            Deactivate
          </DropdownItem>}
          {(true || ['deactivated', 'initiated'].includes(row.status)) && <DropdownItem
            href="#"
            onClick={() => dispatch(branchActivate(row.id))}
          >
            <i className="mdi mdi-play-circle-outline font-size-16 text-success me-1" />{" "}
            Activate
          </DropdownItem>}
          {(true || ['deactivated'].includes(row.status)) && <DropdownItem
            href="#"
            onClick={() => dispatch(branchSuspend(row.id))}
          >
            <i className="mdi mdi-lock-alert-outline font-size-16 text-danger me-1" />{" "}
            Suspend
          </DropdownItem>}


        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ]

  document.title = "Branches | Q-SYS"

  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          title={loading ? "Loading" : "Companies"}
          breadcrumbItem="Branches"
        />


        <Card className="bg-transparent">
          <CardBody>
            <div className="btn-group float-end">
              <Button onClick={onCreate} className="btn btn-primary btn-soft-primary">
                <i className="mdi mdi-plus font-size-18"></i>
                Create
              </Button>
            </div>
            <CardTitle>Branches </CardTitle>

          </CardBody>
          {branches.data && branches.data.length > 0 && <DataTableList
            columns={columns}
            get={get}
            className={`project-list-table table-nowrap align-middle table-borderless`}
            current_page={1}
            last_page={2}
            data={branches.data}
            callBack={get}
            loading={loading}
            setCurrentPage={() => {
            }}

          />}

        </Card>


      </Container>

    </div>
    {branch && <BranchForm
      toggle={toggleForm}
      isOpen={form}
      branch={branch && branch}
      saveChanges={data => {
        setForm(false)
        dispatch(branchPost(data, get))
        setBranch(false)
      }

      } />}
  </React.Fragment>
}
