import React, { useEffect, useState } from "react"
import { Blank } from "../index"
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane, UncontrolledTooltip
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { roleDelete, roleFind, roleGet, rolePost } from "../../store/roles/roleActions"
import { Pre } from "../../components/pre"
import classnames from "classnames"
import { RoleDetails } from "./roleDetails"
import RoleForm from "./roleForm"
import { map } from "lodash"
import { Link } from "react-router-dom"
import images from "../../assets/images"

export const Roles = props => {

  const dispatch = useDispatch()
  const { Roles } = useSelector(state => state)
  const { roles, role, loading } = Roles
  const [form, setForm] = useState(false)
  const toggleForm = () => setForm(!form)
  const get = () => dispatch(roleGet())


  const [active, setActive] = useState(1)


  const toggle = active => setActive(active)

  useEffect(() => {
    if (roles.length > 0) {
      toggle(roles[0].id)
    }
  }, [roles])


  useEffect(get, [])
  document.title = "Roles | Q-SYS"


  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          title={loading ? "Loading" : "Roles"}
          breadcrumbItem="Roles"
        />
        <Card>
          <CardBody>
            <Nav tabs className="nav-tabs-custom nav-justified">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toggleForm()
                  }}
                >
                  <i className="mdi mdi-plus "></i> Create
                </NavLink>
              </NavItem>

              {roles.map(row => <NavItem key={`roles-${row.id}`}>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: active === row.id
                  })} onClick={() => {
                  toggle(row.id)
                }}
                >
                  {row.name}
                </NavLink>
              </NavItem>)}


            </Nav>

            {/*<Pre data={role.users} />*/}


          </CardBody>
          <CardBody>
            <div className="float-end">
              <ButtonGroup className="">
                <Button outline color="danger" onClick={() => dispatch(roleDelete(role.id))}><i className="mdi mdi-trash-can-outline"></i> Delete Role</Button>
              </ButtonGroup>
            </div>

            <div className="avatar-group">
              {map(role.users, (member, index) =>
                !member.avatar || member.avatar !== "Null" ? (
                  <div
                    className="avatar-group-item"
                    key={index}
                  >
                    <Link
                      to="#"
                      className="team-member d-inline-block"
                      id={`user-${member.id}`}
                    >
                      <img
                        src={member.avatar}
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`user-${member.id}`}
                      >
                        {member.name}
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                ) : (
                  <div
                    className="avatar-group-item"
                    key={"_team_" + index}
                  >
                    <Link
                      to="#"
                      className="d-inline-block"
                      id={"member" + member.id}
                    >
                      <div className="avatar-xs">
                                        <span
                                          className={
                                            "avatar-title rounded-circle bg-soft bg-" +
                                            member.color +
                                            " text-" +
                                            member.color +
                                            " font-size-16"
                                          }
                                        >
                                          {member.name.charAt(0)}
                                        </span>
                        <UncontrolledTooltip
                          placement="top"
                          target={"member" + member.id}
                        >
                          {member.name}
                        </UncontrolledTooltip>
                      </div>
                    </Link>
                  </div>
                )
              )}
            </div>
          </CardBody>


        </Card>

        <Card>
          <CardBody>
            <RoleDetails loading={loading} id={active} {...props} />

            <RoleForm isOpen={form} toggle={toggleForm} saveChanges={data => {
              toggleForm()
              dispatch(rolePost(data, (id) => {

                setActive(id)
                get()

              }))

            }} />
          </CardBody>
        </Card>


      </Container>
    </div>
  </React.Fragment>
}
