import {
  USER_GET_REQUEST,
  USER_GET_SUCCESS,
  USER_GET_FAILURE,
  USER_FIND_REQUEST,
  USER_FIND_SUCCESS,
  USER_FIND_FAILURE,
  USER_POST_REQUEST,
  USER_POST_SUCCESS,
  USER_POST_FAILURE,
  USER_ACTION_REQUEST,
  USER_ACTION_SUCCESS,
  USER_ACTION_FAILURE

} from "./userTypes"
import axios from "axios"


export const userGet = () => dispatch => {
  dispatch({ type: USER_GET_REQUEST })
  axios.get("/app/users/get")
    .then(({ data }) => dispatch({ type: USER_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: USER_GET_FAILURE, payload: message }))
}

export const userFind = (id) => dispatch => {
  dispatch({ type: USER_FIND_REQUEST })
  axios.get(`/app/users/get/${id}`)
    .then(({ data }) => dispatch({ type: USER_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: USER_FIND_FAILURE, payload: message }))
}

export const userDelete = (id, callBack) => dispatch => {
  dispatch({ type: USER_ACTION_REQUEST })
  axios.post(`/app/users/delete/`, {id})
    .then(({ data }) => {
      dispatch({ type: USER_ACTION_SUCCESS, payload: data })
      dispatch(userGet())

    })
    .catch(({ message }) => dispatch({ type: USER_ACTION_FAILURE, payload: message }))
    .finally(callBack)
}

export const userPost = (data, callBack) => dispatch => {
  dispatch({ type: USER_POST_REQUEST })
  axios.post(`/app/users/submit`, data)
    .then(({ data }) => {
      dispatch({ type: USER_POST_SUCCESS, payload: data })
      if (!callBack) {
        dispatch(userGet())
        dispatch(userFind(data.id))
      } else {
        callBack(data)
      }
    })
    .catch(({ message }) => dispatch({ type: USER_POST_FAILURE, payload: message }))
}



