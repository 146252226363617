import React from "react"
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { Pre } from "../../../components/pre"
import axios from "axios"


export default class DepartmentForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      fields: false,
      form: false
    }
  }

  componentDidMount = () => this.init()

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen == true) this.init()
  }

  init = () => {
    const { department } = this.props
    axios
      .post("app/departments/form/", { id: department })
      .then(res => this.setState({ form: res.data, fields: res.data.fields }))
  }

  handleChange = (label, value) => {
    let { fields } = this.state
    fields[label] = value
    this.setState({ fields })
  }

  render() {
    const { isOpen, toggle, department, saveChanges } = this.props
    const { loading, fields, form } = this.state
    return <Modal isOpen={isOpen} toggle={toggle}>
      {form && <>
        <ModalHeader>

          {department.id && <>Edit : {department.department_name}</>}
          {!department.id && <>Create Department</>}
        </ModalHeader>
        <ModalBody>

          <div className="mb-3">
            <Label htmlFor="form-department-name">Company</Label>
            <select
              value={fields.companies_id}
              onChange={e => this.handleChange("companies_id", e.target.value)}
              type="text"
              className="form-control"
              id="form-department-name"
              placeholder=""
            >
              <option value={0}>Select Company</option>

              {form.companies && typeof form.companies === "object" && form.companies.map(row => <option
                key={`company-${row.id}`} value={row.id}>{row.company_name}</option>)}

            </select>
          </div>
          {form.branches.filter(row => row.companies_id == fields.companies_id).length > 0 && <div className="mb-3">
            <Label htmlFor="form-department-branches_id">Branches</Label>
            <select
              value={fields.branches_id}
              onChange={e => this.handleChange("branches_id", e.target.value)}
              type="text"
              className="form-control"
              id="form-department-branches_id"
              placeholder=""
            >
              <option>SELECT BRANCH</option>
              {form.branches &&
              (form.branches.filter(row => row.companies_id == fields.companies_id)).map(row =>
                <option key={`company-${row.id}`} value={row.id}>{row.branch_name}</option>)}

            </select>
          </div>}
          <div className="mb-3">
            <Label htmlFor="form-department-name">Department Name</Label>
            <Input
              value={fields.department_name}
              onChange={e => this.handleChange("department_name", e.target.value)}
              type="text"
              className="form-control"
              id="form-department-name"
              placeholder=""
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button outline color="dark" onClick={toggle}>Close</Button>
          <Button outline color="success" onClick={() => saveChanges(fields)}>
            Save Changes</Button>
        </ModalFooter>
      </>}
    </Modal>
  }


}
