import {
  COMPANY_GET_REQUEST,
  COMPANY_GET_SUCCESS,
  COMPANY_GET_FAILURE,
  COMPANY_FIND_REQUEST,
  COMPANY_FIND_SUCCESS,
  COMPANY_FIND_FAILURE,
  COMPANY_POST_REQUEST,
  COMPANY_POST_SUCCESS,
  COMPANY_POST_FAILURE,
  COMPANY_ACTION_REQUEST,
  COMPANY_ACTION_SUCCESS,
  COMPANY_ACTION_FAILURE

} from "./companyTypes"


const init = {
  loading: false,
  companies : {},
  company: false,
  error : ""
}

const Company = (state = init, action) => {
  switch(action.type) {
    case COMPANY_GET_REQUEST : {return {...state, loading: true, error: ""}}
    case COMPANY_GET_SUCCESS : {return {...state, loading: false, companies: action.payload, error: ""}}
    case COMPANY_GET_FAILURE : {return {...state, loading: false, error: action.payload}}

    case COMPANY_FIND_REQUEST : {return {...state, loading: true, error: "", company: false}}
    case COMPANY_FIND_SUCCESS : {return {...state, loading: false, company: action.payload, error: ""}}
    case COMPANY_FIND_FAILURE : {return {...state, loading: false, error: action.payload}}

    case COMPANY_POST_REQUEST : {return {...state, loading: true, error: ""}}
    case COMPANY_POST_SUCCESS : {return {...state, loading: false, error: ""}}
    case COMPANY_POST_FAILURE : {return {...state, loading: false, error: action.payload}}

    case COMPANY_ACTION_REQUEST : {return {...state, loading: true, error: ""}}
    case COMPANY_ACTION_SUCCESS : {return {...state, loading: false, error: ""}}
    case COMPANY_ACTION_FAILURE : {return {...state, loading: false, error: action.payload}}



    default : {
      return { ...state }
    }
  }

}

export default Company;
