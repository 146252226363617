import React from "react"
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { Pre } from "../../../components/pre"
import axios from "axios"


export default class SMSSettingsForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      fields: false,
      form: false
    }
  }

  componentDidMount = () => this.init()

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen == true) this.init()
  }

  init = () => {
    if (!this.props.isOpen) return false

    const { sms_settings } = this.props
    axios
      .post("app/settings/sms/form/", { id: sms_settings })
      .then(res => this.setState({ form: res.data, fields: res.data.fields }))
  }

  handleChange = (label, value) => {
    let { fields } = this.state
    fields[label] = value
    this.setState({ fields })
  }

  render() {
    const { isOpen, toggle, sms_settings, saveChanges } = this.props
    const { loading, fields, form } = this.state


    return <Modal isOpen={isOpen} toggle={toggle}>
      <Form method="post" onSubmit={e => {
        e.preventDefault()
        saveChanges(fields)
      }}>
        <ModalHeader>
          {loading && "Loading...."}

          {sms_settings.id && <>Edit : {sms_settings.sms_settings}</>}
          {!sms_settings.id && <>Create Escalation Level</>}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-department-name">Company</Label>
                <select
                  value={fields.company}
                  onChange={e => this.handleChange("company", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-department-name"
                  placeholder=""
                >
                  <option value={0}>Select Company</option>

                  {form.companies && typeof form.companies === "object" && form.companies.map(row => <option
                    key={`company-${row.id}`} value={row.id}>{row.company_name}</option>)}

                </select>
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-sms_settings-name">Provider</Label>
                <Input
                  value={fields.provider}
                  onChange={e => this.handleChange("provider", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-provider-name"
                  placeholder="SMS Service Provider"
                />
              </div>
            </Col>
          </Row>

          <div className="mb-3">
            <Label htmlFor="form-sms_settings-name">Callback URL</Label>
            <Input
              value={fields.callback_url}
              onChange={e => this.handleChange("callback_url", e.target.value)}
              type="text"
              className="form-control"
              id="form-callback_url-name"
              placeholder="https://sub.domain.com/respond/to/me"
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="form-sms_settings-name">API Key</Label>
            <Input
              value={fields.api_key}
              onChange={e => this.handleChange("api_key", e.target.value)}
              type="text"
              className="form-control"
              id="form-api_key-name"
              placeholder=""
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="form-sms_settings-name">API Secret</Label>
            <Input
              value={fields.api_secret}
              onChange={e => this.handleChange("api_secret", e.target.value)}
              type="text"
              className="form-control"
              id="form-api_secret-name"
              placeholder=""
            />
          </div>

        </ModalBody>
        <ModalFooter>
          <Button outline color="dark" onClick={toggle}>Close</Button>
          <Button outline color="success" type="submit">
            Save Changes</Button>
        </ModalFooter>
      </Form>
    </Modal>
  }


}
