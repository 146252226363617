import {
  COMPANY_GET_REQUEST,
  COMPANY_GET_SUCCESS,
  COMPANY_GET_FAILURE,
  COMPANY_FIND_REQUEST,
  COMPANY_FIND_SUCCESS,
  COMPANY_FIND_FAILURE,
  COMPANY_POST_REQUEST,
  COMPANY_POST_SUCCESS,
  COMPANY_POST_FAILURE,
  COMPANY_ACTION_REQUEST,
  COMPANY_ACTION_SUCCESS,
  COMPANY_ACTION_FAILURE

} from "./companyTypes"
import axios from "axios"


export const companyGet = () => dispatch => {
  dispatch({ type: COMPANY_GET_REQUEST })
  axios.get(`app/companies/get`)
    .then(({ data }) => dispatch({ type: COMPANY_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: COMPANY_GET_FAILURE, payload: message }))
}

export const companyFind = (id) => dispatch => {
  dispatch({ type: COMPANY_FIND_REQUEST })
  axios.get(`app/companies/get/${id}`)
    .then(({ data }) => dispatch({ type: COMPANY_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: COMPANY_FIND_FAILURE, payload: message }))
}

export const companyDelete = (id, callBack) => dispatch => {
  dispatch({ type: COMPANY_ACTION_REQUEST })
  axios.post(`app/companies/delete/`, { id })
    .then(({ data }) => {
      dispatch({ type: COMPANY_ACTION_SUCCESS, payload: data })
      dispatch(companyGet())

    })
    .catch(({ message }) => dispatch({ type: COMPANY_ACTION_FAILURE, payload: message }))
    .finally(callBack)
}

export const companyActivate = (id, callBack) => dispatch => {
  dispatch({ type: COMPANY_ACTION_REQUEST })
  axios.post(`app/companies/activate/`, { id })
    .then(({ data }) => {
      dispatch({ type: COMPANY_ACTION_SUCCESS, payload: data })
      dispatch(companyGet())

    })
    .catch(({ message }) => dispatch({ type: COMPANY_ACTION_FAILURE, payload: message }))
    .finally(callBack)
}

export const companyDeactivate = (id, callBack) => dispatch => {
  dispatch({ type: COMPANY_ACTION_REQUEST })
  axios.post(`app/companies/deactivate/`, { id })
    .then(({ data }) => {
      dispatch({ type: COMPANY_ACTION_SUCCESS, payload: data })
      dispatch(companyGet())

    })
    .catch(({ message }) => dispatch({ type: COMPANY_ACTION_FAILURE, payload: message }))
    .finally(callBack)
}

export const companySuspend = (id, callBack) => dispatch => {
  dispatch({ type: COMPANY_ACTION_REQUEST })
  axios.post(`app/companies/suspend/`, { id })
    .then(({ data }) => {
      dispatch({ type: COMPANY_ACTION_SUCCESS, payload: data })
      dispatch(companyGet())

    })
    .catch(({ message }) => dispatch({ type: COMPANY_ACTION_FAILURE, payload: message }))
    .finally(callBack)
}

export const companyPost = (data, callBack) => dispatch => {
  dispatch({ type: COMPANY_POST_REQUEST })
  axios.post(`app/companies/submit`, data)
    .then(({ data }) => {
      dispatch({ type: COMPANY_POST_SUCCESS, payload: data })
      if (!callBack) {
        dispatch(companyGet())
        dispatch(companyFind(data.id))
      } else {
        callBack(data)
      }
    })
    .catch(({ message }) => dispatch({ type: COMPANY_POST_FAILURE, payload: message }))
}


export const companyPatch = (row, callBack) => dispatch => {

}
export const companyDuplicate = (row, callBack) => dispatch => {

}

