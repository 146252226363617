import React, { useEffect } from "react"
import { ButtonGroup, Button, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Label, Row } from "reactstrap"
import { roleDelete, roleFind } from "../../store/roles/roleActions"
import { useDispatch, useSelector } from "react-redux"


export const RoleDetails = (props) => {
  const dispatch = useDispatch()
  const { Roles } = useSelector(state => state)
  const { role, loading } = Roles
  const find = (id) => dispatch(roleFind(id))
  const { id } = props

  useEffect(() => find(id), [id])


  return <>

    <div>
      {role && role.modules.map((module, index) => <div key={`role-module-${index}`}>

        <CardTitle>{module.module}</CardTitle>
        <>

          <Row className="mb-4">
            {role.permissions.filter(row => row.module === module.module).map(permission => <Col
              sm={3}
              key={`permission-${permission.id}`}>
              <FormGroup>
                <Input id={`permission-${permission.id}`} type="checkbox" />
                <Label for={`permission-${permission.id}`} style={{ marginLeft: 5 }}>{permission.name}</Label>
              </FormGroup>
            </Col>)}

          </Row>
        </>


      </div>)}

    </div>

  </>

}
