import React from "react"
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { Pre } from "../../../components/pre"
import axios from "axios"


export default class QuestionForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      fields: false,
      form: false,
      branches: [],
      departments: []
    }
  }

  componentDidMount = () => this.init()

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen == true) this.init()


    if (prevState.fields && prevState.fields != this.state.fields) {
      this.updateBranches()
    }

  }

  updateBranches = () => {

    const { fields, form } = this.state
    this.setState({ branches: form.branches.filter(row => row.companies_id == fields.companies_id) }, this.updateDepartments)
  }

  updateDepartments = () => {
    const { fields, form, branches } = this.state
    this.setState({
      departments: form.departments.filter(row => {

        const condition_1 = row.companies_id == fields.companies_id
        const condition_2 = branches.length > 0 && row.branches_id == fields.branches_id

        return condition_1 || condition_2

      })
    }, () => console.log("Departments Updated"))


  }


  init = () => {
    console.clear()
    const { question } = this.props
    axios
      .post("app/questions/form/", { id: question })
      .then(res => this.setState({
        form: res.data,
        fields: res.data.fields
      }, this.updateBranches))
  }

  handleChange = (label, value) => {
    let { fields } = this.state
    fields[label] = value
    this.setState({ fields }, () => {
      this.updateBranches()
      this.updateDepartments()
    })
  }


  render() {
    const { isOpen, toggle, question, saveChanges } = this.props
    const { loading, fields, form, branches, departments } = this.state
    return <Modal isOpen={isOpen} toggle={toggle}>
      {!loading && form && <>
        <ModalHeader>

          {question.id && <>Edit : {question.question_name}</>}
          {!question.id && <>Create Question</>}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={departments.length > 0 || branches.length > 0 ? 6 : 12 }>

              <div className="mb-3">
                <Label htmlFor="form-question-name">Company</Label>
                <select
                  value={fields.companies_id}
                  onChange={e => this.handleChange("companies_id", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-question-name"
                  placeholder=""
                >
                  <option value={0}>Choose Companies</option>

                  {form.companies.map(row => <option
                    key={`company-${row.id}`} value={row.id}>{row.company_name}</option>)}

                </select>
              </div>
            </Col>
            {branches.length > 0 && <Col sm={6}>

              <div className="mb-3">
                <Label htmlFor="form-question-branches_id">Branch {branches.length}</Label>
                <select
                  value={fields.branches_id}
                  onChange={e => this.handleChange("branches_id", e.target.value)}
                  className="form-control"
                  id="form-question-branches_id"
                  placeholder=""
                >
                  <option value={0}>Choose Branches</option>
                  {branches.map(row => <option
                    key={`company-${row.id}`} value={row.id}>{row.branch_name}</option>)}

                </select>
              </div>


            </Col>}


            {departments.length > 0 && <Col sm={branches.length > 0 ? 12 : 6}>
              <div className="mb-3">
                <Label htmlFor="form-question-branches_id">Department {departments.length}</Label>
                <select
                  value={fields.department_id}
                  onChange={e => this.handleChange("department_id ", e.target.value)}
                  className="form-control"
                  id="form-question-department"
                >
                  <option>Choose Departments</option>
                  {departments.map(row => <option
                    key={`department-company-${row.id}`} value={row.id}>{row.department_name}</option>)}
                </select>
              </div>
            </Col>}
          </Row>


          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-question-name">Aspect</Label>
                <select
                  value={fields.aspect_id}
                  onChange={e => this.handleChange("aspect_id", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-question-name"
                  placeholder=""
                >
                  <option>Aspect</option>

                  {form.aspects && typeof form.aspects === "object" && form.aspects.map(row =>
                    <option key={`aspect-${row.id}`} value={row.id}>{row.aspect_name}</option>)}

                </select>
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-is_mandatory-name">Mandatory</Label>
                <select
                  value={fields.is_mandatory}
                  onChange={e => this.handleChange("is_mandatory", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-is_mandatory-name">
                  {form.enums.is_mandatory.map(row =>
                    <option key={`is_mandatory-${row}`} value={row}>{row}</option>)}
                </select>
              </div>
            </Col>
          </Row>

          <div className="mb-3">
            <Label htmlFor="form-question-name">Question Type</Label>
            <select
              value={fields.question_type_id}
              onChange={e => this.handleChange("question_type_id", e.target.value)}
              type="text"
              className="form-control"
              id="form-question-name"
              placeholder=""
            >
              <option>Choose Question Type</option>

              {form.question_types && typeof form.question_types === "object" && form.question_types.map(row =>
                <option key={`question_types-${row.id}`} value={row.id}>{row.question_type}</option>)}

            </select>
          </div>
          <div className="mb-3">
            <Label htmlFor="form-question-name">Question</Label>
            <Input
              value={fields.question}
              onChange={e => this.handleChange("question", e.target.value)}
              type="text"
              className="form-control"
              id="form-question-name"
              placeholder=""
            />
          </div>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-response-type">Response Type</Label>
                <select
                  value={fields.response_type_id}
                  onChange={e => this.handleChange("response_type_id", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-response_type"
                  placeholder=""
                >
                  <option>Choose Question Type</option>
                  {form.response_types && typeof form.response_types === "object" && form.response_types.map(row =>
                    <option key={`response_types-${row.id}`} value={row.id}>{row.response_type}</option>)}
                </select>
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-question-name">Negative Review Level</Label>
                <Input
                  value={fields.negative_review_level}
                  onChange={e => this.handleChange("negative_review_level", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-question-name"
                  placeholder=""
                />
              </div>
            </Col>
          </Row>

        </ModalBody>
        <ModalFooter>
          <Button outline color="dark" onClick={toggle}>Close</Button>
          <Button outline color="success" onClick={() => saveChanges(fields)}>
            Save Changes</Button>
        </ModalFooter>
      </>}
    </Modal>
  }


}
